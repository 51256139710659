<template>
  <q-header
    class="navHeader bg-card-bg"
    elevated
    reveal
  >
    <q-toolbar
      class="headerToolbar full-height"
    >
      <img
        class="headerLogo"
        src="@/assets/logo/wc-logo-white.png"
      >
      <q-space
        v-if="isAuthenticated && !auth0IsLoading"
      />
      <q-tabs
        v-if="isAuthenticated && !auth0IsLoading && currentPlan !== ''"
        v-model="navTab"
        class="navTabs"
        no-caps
      >
        <q-route-tab
          name="dashboard"
          :label="t('Header.Dashboard')"
          :to="{ name: 'dashboardView' }"
          exact
          replace
        />
        <q-route-tab
          name="account"
          :label="t('Header.Account')"
          :to="{ name: 'accountView' }"
          replace
        />
        <q-route-tab
          name="invoices"
          :label="t('Header.Invoices')"
          :to="{ name: 'invoicesView' }"
          exact
          replace
        />
      </q-tabs>
      <q-space />
      <q-item
        v-if="isAuthenticated && userName !== ''"
        class="q-pa-none q-pl-sm q-pr-sm"
        clickable
      >
        <q-item-section avatar>
          <q-avatar>
            <img :src="profilePicture">
          </q-avatar>
        </q-item-section>
        <q-item-section>
          {{ userName }}
        </q-item-section>
        <q-menu fit>
          <q-list>
            <q-item
              clickable
              @click="logout"
            >
              <q-item-section>
                {{ t('Header.Logout') }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-item
        v-else-if="!auth0IsLoading && isAuthenticated"
        class="q-pa-none q-pl-sm q-pr-sm"
        clickable
        @click="logout"
      >
        <q-item-section avatar>
          <q-avatar
            color="grey-8"
            text-color="white"
            icon="person"
          />
        </q-item-section>
        <q-item-section>
          {{ t('Header.Logout') }}
        </q-item-section>
      </q-item>
    </q-toolbar>
  </q-header>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import { useI18n } from 'vue-i18n'
import { useAuth0 } from '@auth0/auth0-vue'

// Global
const { t } = useI18n({ useScope: 'global' })
const store = useStore()
const auth0 = useAuth0()

// User Info
const userName = computed(() => {
  return store.getters['Global/user/getFirstName']
})

const profilePicture = computed(() => {
  return store.getters['Global/user/getProfilePicture']
})

const isAuthenticated = computed(() => {
  return auth0.isAuthenticated.value
})

const auth0IsLoading = computed(() => {
  return auth0.isLoading.value
})

// Plan Info

const currentPlan = computed(() => {
  return store.getters['Global/plans/getCurrentPlan']
})

// Nav Logic

const navTab = ref('dashboard')

// Logout
const logout = function () {
  auth0.logout({
    logoutParams: {
      returnTo: `${window.origin}/dashboard`
    }
  })
}

// Loging
const login = function () {
  auth0.loginWithRedirect()
}
</script>

<style lang="scss" scoped>

.navHeader {
  height: 75px;
  line-height: 75px;
}
.headerToolbar {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
}

.headerLogo {
  width: 150px;
}

.navTabs {
  height: 75px;
}

@media (max-width: 1024px) {
  .headerToolbar {
    width: 100%;
  }
}
</style>
